import { createTheme } from "@mui/material/styles"
import { getSavedLanguage } from "@/i18n"
import { deDE, enUS, esES, frFR, itIT, jaJP, svSE } from "@mui/material/locale"
import {
    deDE as dataGridDeDE,
    enUS as dataGridEnUS,
    esES as dataGridEsES,
    frFR as dataGridFrFR,
    itIT as dataGridItIT,
    jaJP as dataGridJaJP,
    svSE as dataGridSvSE,
} from "@mui/x-data-grid/locales"

const baseColors = [
    "#7A80BD",
    "#B1C1FB",
    "#7D7F9A",
    "#F8B77D",
    "#F39C8C",
    "#5EDC99",
    "#6AAFE3",
    "#B77BC6",
    "#4ED9B3",
    "#FFE6B3",
    "#FFB27D",
    "#8C9DB3",
]

export const citationColors = Array(5).fill(baseColors).flat()

function CustomScrollbar(options?: { track: string; thumb: string; active: string }): {
    scrollbarColor: string
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        width: string
        height: string
        backgroundColor: string
    }
    "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
        backgroundColor: string
    }
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: string
        backgroundColor: string
        border: string
    }
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: string
    }
} {
    const { track = "#f5f5f5", thumb = "#bdbdbd", active = "#9e9e9e" } = options || {}

    return {
        scrollbarColor: `${thumb} / ${track}`,
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "12px",
            height: "12px",
            backgroundColor: track,
        },
        "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            // a bit transparent to see the thumb color
            backgroundColor: `${track}99`,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: "6px",
            backgroundColor: thumb,
            border: "3px solid #f5f5f5",
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: active,
        },
    }
}

const lang = getSavedLanguage()

const localeSettings = {
    de: { ...deDE, ...dataGridDeDE },
    fr: { ...frFR, ...dataGridFrFR },
    es: { ...esES, ...dataGridEsES },
    it: { ...itIT, ...dataGridItIT },
    sv: { ...svSE, ...dataGridSvSE },
    ja: { ...jaJP, ...dataGridJaJP },
    en: { ...enUS, ...dataGridEnUS },
}[lang] || { ...enUS, ...dataGridEnUS }

const theme = createTheme(
    {
        palette: {
            staticColors: {
                bodyColor: "#E4EFFF",
                headerColor: "#fff",
                showreelColor: "#4e7cbf",
                menuColor: "#003366",
                questionBoxColor: "#fff",
                questionDefaultBoxColor: "#e6ccff",
                userChatBoxColor: "#bed7fd",
                footerColor: "#122e52",
                exampleBoxBackgroundLight: "#D2EAFF",
                exampleBoxBackgroundDark: "#4F7CBF",
                listHeader: "#4F7CBF",
                listElementLight: "#FFF",
                listElementDark: "#D2E9FF",
            },
            primary: {
                main: "#4e7cbf",
            },
            secondary: {
                main: "#cecece",
            },
            background: {
                default: "#f1f1f1",
                paper: "#fff",
            },
        },
        typography: {
            fontFamily: "Segoe UI",
            fontSize: 14,

            h1: {
                fontSize: 26,
                lineHeight: 1.2,
            },
            h2: {
                fontSize: 22,
                lineHeight: 1.2,
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: themeParam => ({
                    body: CustomScrollbar(),
                }),
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        fontFamily: "Comfortaa",
                        fontSize: "14px",
                        textTransform: "none",
                        // paddingTop: "6px",
                        // paddingBottom: "5px",
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontFamily: "Comfortaa",
                        fontSize: "14px",
                        textTransform: "none",
                        paddingTop: "6px",
                        paddingBottom: "5px", // Decreased to take shadow into account
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    enterDelay: 400,
                    enterNextDelay: 400,
                    enterTouchDelay: 400,
                },
                styleOverrides: {
                    tooltip: {
                        backgroundColor: "#888888",
                        fontSize: "1.02rem",
                        maxWidth: "500px",
                    },
                },
            },
            // @ts-ignore
            MuiDataGrid: {
                styleOverrides: {
                    toolbarContainer: {
                        borderRadius: "4px",
                        borderBottomRightRadius: "0px",
                        borderBottomLeftRadius: "0px",
                    },
                    root: {
                        borderRadius: "4px",
                        borderTopRightRadius: "0px",
                        borderTopLeftRadius: "0px",
                    },
                    columnHeader: {
                        borderRadius: "0px !important",
                    },
                },
            },
        },
    },
    localeSettings
)

export default theme
