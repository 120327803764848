import { renderToStaticMarkup } from "react-dom/server"
import { citationColors } from "@/theme"
import { processFileName } from "@/pages/documents/Documents"
import { marked } from "marked"

type HtmlParsedAnswer = {
    fragments: AnswerFragment[]
    citations: string[]
    followupQuestions: string[]
}

export enum AnswerFragmentType {
    Citation = "citation",
    Chart = "chart",
    Text = "text",
}

export type AnswerFragment = {
    text: string
    type: AnswerFragmentType
}

function decodeHtmlEntities(str: string) {
    const doc = new DOMParser().parseFromString(str, "text/html")
    return doc.documentElement.textContent
}

const emptySymbol = "&nbsp;"

function prepareAnswer(answer: string) {
    let newAnswer = ""
    let previousLine = ""
    let firstLineOfTable = ""
    let isTableProcessed = false

    // Remove the redundant ```markdown ``` wrapper
    answer = answer.replace(/```markdown\s*([\s\S]*?)```/gs, "$1")

    answer.split("\n").forEach(line => {
        let newLine = line

        const isTableRow = line.startsWith("|")

        if (previousLine.startsWith("|") && !isTableRow) {
            newLine = `\n${line}`
        }

        // if table has no header row,then we need add empty header row to make it work with markdown
        if (isTableRow && firstLineOfTable !== "" && !isTableProcessed) {
            if (!line.includes("----")) {
                const numberColumns = firstLineOfTable.split("|").length - 2
                //remove previous line
                newAnswer = newAnswer.slice(0, -previousLine.length - 1)

                newAnswer += "|"
                for (let i = 0; i < numberColumns; i++) {
                    newAnswer += " |"
                }

                newAnswer += "\n|"
                for (let i = 0; i < numberColumns; i++) {
                    newAnswer += "----|"
                }

                newAnswer += `\n${previousLine}\n`
            }

            isTableProcessed = true
        }

        if (isTableRow && firstLineOfTable === "") {
            firstLineOfTable = line
        }

        if (!isTableRow && isTableProcessed) {
            firstLineOfTable = ""
            isTableProcessed = false
        }

        if (newLine === "") {
            newAnswer += `${emptySymbol}\n`
        } else {
            newAnswer += `${newLine}\n`
        }

        previousLine = line
    })

    newAnswer = newAnswer.replace(/^\n+|\n+$/g, "").trim()
    newAnswer = newAnswer.replace("mermaid ", "mermaid\n")

    //Remove body styles if they are present in format body {... }
    newAnswer = newAnswer.replace(/body\s*{[^}]*}/g, "")

    //Remove empty symbols from the end of the answer
    while (newAnswer.endsWith(`${emptySymbol}\n`)) {
        newAnswer = newAnswer.slice(0, -(`${emptySymbol}\n`.length + 1))
    }

    while (newAnswer.includes("&nbsp;\n```")) {
        newAnswer = newAnswer.replace("&nbsp;\n```", "```")
    }

    let html = ""

    html = String(marked.parse(answer))

    const mermaidBlocks = html.match(/<code class="language-mermaid">([\s\S]*?)<\/code>/g) || []

    mermaidBlocks.forEach(block => {
        const blockContent = block
            .replace(/<code class="language-mermaid">/g, "```mermaid\n")
            .replace(/<\/code>/g, "\n```")
        // .replace(/&quot;/g, '"')
        // .replace(/&ndash;/g, "-")
        // .replace(/&mdash;/g, "--")
        // .replace(/&lt;/g, "<")
        // .replace(/&gt;/g, ">")
        // .replace(/&amp;/g, "&")
        // .replace(/&nbsp;/g, " ")
        // @ts-ignore
        html = html.replace(block, decodeHtmlEntities(blockContent))
    })

    const anyCodeBlocks = html.match(/<code class="language-[^"]*">([\s\S]*?)<\/code>/g) || []
    anyCodeBlocks.forEach(block => {
        // @ts-ignore
        html = html.replace(block, decodeHtmlEntities(block))
    })

    // html = decodeHtmlEntities(html)
    // remove <code> and </code> tags for "<code class="language-html">" blocks
    // html = html.replace(/<code class="language-html">/g, "").replace(/<\/code>/g, "")
    // console.log(html)
    html = html.replace(/<p>/g, "").replace(/<\/p>/g, "").trim()
    html = html.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    return html
}

export function parseAnswerToHtml(
    answer: string,
    onCitationClicked?: (citationFilePath: string) => void
): HtmlParsedAnswer {
    const followupQuestions: string[] = []

    const answerWithoutFollowUpQuestions = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
        followupQuestions.push(content)

        return ""
    })

    //remove all fonts styles from the answer
    const answerWithoutCustomFonts = answerWithoutFollowUpQuestions
        .replace(/font-family:.*?;/g, "")
        .replace(/@font-face\s*{[^}]*}/g, "")

    const answerWithMarkdown = answer.includes("google.visualization")
        ? answerWithoutCustomFonts.replace("```html", "").replace("```", "")
        : prepareAnswer(answerWithoutCustomFonts)

    const citations: string[] = []

    const citationsRegex = /\[([^:\n]+\.[a-z]{1,6}:[a-f0-9]{24}:\d+:\d\.\d+)]/g

    const chartRegex = /(```\s*mermaid[\s\S]*?```)/g

    const htmlPageRegex = /<!DOCTYPE html>[\s\S]*<\/html>/g

    const parts = answerWithMarkdown
        .trim()
        .split(new RegExp(`${citationsRegex.source}|${chartRegex.source}|${htmlPageRegex}`, "g"))

    const fragments: AnswerFragment[] = []

    const addFragmentToFragments = (text: string, type: AnswerFragmentType) => {
        const previousFragmentType = fragments.length > 0 ? fragments[fragments.length - 1].type : null

        if (
            previousFragmentType != null &&
            previousFragmentType !== AnswerFragmentType.Chart &&
            type !== AnswerFragmentType.Chart
        ) {
            fragments[fragments.length - 1].text += text
        } else if (text.trim() !== emptySymbol) {
            fragments.push({ text, type })
        }
    }

    parts
        .filter(part => part && part.trim() !== "")
        .map((part, index) => {
            const is_citation = `[${part}]`.match(citationsRegex) != null
            const is_chart = part.match(chartRegex) != null || part.includes("google.visualization")

            const is_last = parts.indexOf(part) === parts.length - 1

            // Remove empty symbols from the end of the part
            if (is_last) {
                part = part.trim()

                while (part.endsWith(emptySymbol)) {
                    part = part.trim()

                    part = part.slice(0, -(emptySymbol.length + 1))
                }
            }

            if (is_citation && onCitationClicked) {
                let citationIndex: number
                if (citations.indexOf(part) !== -1) {
                    citationIndex = citations.indexOf(part) + 1
                } else {
                    citations.push(part)
                    citationIndex = citations.length
                }

                const citationInfo = part.split(":")

                const citationMarkup = renderToStaticMarkup(
                    <a className="supContainer" onClick={() => onCitationClicked(part)}>
                        <sup
                            title={processFileName(citationInfo[0])}
                            style={{ backgroundColor: citationColors[citationIndex - (1 % citationColors.length)] }}
                            data-id={citationInfo[1]}
                            data-page={citationInfo[2]}>
                            {citationIndex}
                        </sup>
                    </a>
                )

                addFragmentToFragments(citationMarkup, AnswerFragmentType.Citation)
            } else if (is_chart) {
                addFragmentToFragments(part, AnswerFragmentType.Chart)
            } else {
                addFragmentToFragments(part, AnswerFragmentType.Text)
            }
        })

    return {
        fragments,
        citations,
        followupQuestions,
    }
}
