import React from "react"
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material"
import { HelpOutline, Login, Logout, Settings } from "@mui/icons-material"
import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { loginRequest } from "@/config/authConfig"
import { useNavigate } from "react-router-dom"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"
import RssFeedIcon from "@mui/icons-material/RssFeed"

interface IUserMenuProps {
    anchorEl: null | HTMLElement
    open: boolean
    handleClose: () => void
}

const fontFamilyStyle = { fontFamily: "'Comfortaa', sans-serif" }

export const UserMenu: React.FC<IUserMenuProps> = ({ anchorEl, open, handleClose }) => {
    const isAuthenticated = useIsAuthenticated()
    const { instance } = useMsal()
    const navigate = useNavigate()

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(error => console.log(error))
        handleClose()
    }

    const handleLogout = async () => {
        try {
            const activeAccount = instance.getActiveAccount()
            localStorage.removeItem("taskInfos")
            await instance.logoutRedirect({ account: activeAccount })
            await instance.clearCache({ account: activeAccount })
        } catch (error) {
            if (error instanceof DOMException && error.name === "QuotaExceededError") {
                console.log("Storage is full, clearing Storage")
                // localStorage.clear()
                sessionStorage.clear()
                await instance.logoutRedirect()
                await instance.clearCache()
            } else {
                console.log(error)
            }
        } finally {
            handleClose()
        }
    }

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    ...fontFamilyStyle,
                    "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 11,
                    },
                },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
            {isAuthenticated && (
                <MenuItem
                    onClick={() => {
                        navigate("/user")
                        handleClose()
                    }}
                    sx={fontFamilyStyle}>
                    <Avatar className="notranslate" /> My account
                </MenuItem>
            )}
            {isAuthenticated && <Divider />}
            {isAuthenticated && (
                <MenuItem
                    onClick={() => {
                        navigate("/settings")
                        handleClose()
                    }}
                    sx={fontFamilyStyle}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
            )}
            {isAuthenticated && (
                <MenuItem
                    onClick={() => {
                        navigate("/connectors")
                        handleClose()
                    }}
                    sx={fontFamilyStyle}>
                    <ListItemIcon>
                        <CloudQueueIcon fontSize="small" />
                    </ListItemIcon>
                    Connectors
                </MenuItem>
            )}
            <MenuItem
                onClick={() => {
                    navigate("/announcements")
                    handleClose()
                }}
                sx={fontFamilyStyle}>
                <ListItemIcon>
                    <RssFeedIcon fontSize="small" />
                </ListItemIcon>
                Announcements
            </MenuItem>
            <MenuItem
                onClick={() => {
                    navigate("/help-and-resources")
                    handleClose()
                }}
                sx={fontFamilyStyle}>
                <ListItemIcon>
                    <HelpOutline fontSize="small" />
                </ListItemIcon>
                Help & Resources
            </MenuItem>
            {isAuthenticated ? (
                <MenuItem onClick={handleLogout} sx={fontFamilyStyle}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            ) : (
                <MenuItem onClick={handleLogin} sx={fontFamilyStyle}>
                    <ListItemIcon>
                        <Login fontSize="small" />
                    </ListItemIcon>
                    Login
                </MenuItem>
            )}
        </Menu>
    )
}
