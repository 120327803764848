import React from "react"
import { RouterProvider, useNavigate } from "react-router-dom"
import router from "@/router/config"
import { useDispatch } from "react-redux"
import { useEffectOnce, useLocalStorage, useReadLocalStorage } from "usehooks-ts"
import { Worker } from "@react-pdf-viewer/core"
import { useHotkeys } from "react-hotkeys-hook"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import {
    setAnswerGenerationPromptTemplate,
    setAnswerStream,
    setAssistantType,
    setCitationsHighlightMode,
    setCognitiveSearchQueryGenerationPromptTemplate,
    setDocsPreselectSearchThreshold,
    setDocsSearchThreshold,
    setExcludeCategory,
    setImproveSearchQueryMode,
    setModel,
    setModelProvider,
    setPdfCitationDisplayMode,
    setPreselectContexFilesMode,
    setSearchApproach,
    setSelectedDocuments,
    setShowWelcomeMessage,
    setTemperatureCount,
    setUseSuggestFollowupQuestions,
} from "@/slices/chatSlice"
import {
    AssistantTypes,
    ImproveSearchQueryModes,
    ModelVersions,
    PreselectContextFilesModes,
    SearchApproaches,
} from "@/api"

import "react-toastify/dist/ReactToastify.css"
import { toast, ToastContainer } from "react-toastify"

import { CssBaseline, ThemeProvider } from "@mui/material"
import theme from "./theme"
import { ModalProvider } from "@/components/ModalProvider"
import axios from "axios"
import { msalInstance } from "@/index"
import { loginRequest } from "@/config/authConfig"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import LoginSuccessListener from "@/components/LoginSuccessListener"
import { DownloadType } from "@/components/DownloadFromBlob/downloadFromBlob"
import { CitationsHighlightMode } from "@/components/CitationPDFViewer/CitationPDFViewer"
import { isMobile } from "@/utils/mobileDetect"
import { setWorkflowJSON } from "@/slices/workflowsSlice"
import { ProviderOptions } from "@/components/Settings/useSettings"
import { getValidAccessToken } from "@/utils/accessToken"
import "./i18n"
import { withTranslation } from "react-i18next"
import { reactPlugin } from "@/telemetry/appInsights"

axios.interceptors.request.use(
    async config => {
        const accessToken = await getValidAccessToken()

        if (accessToken) {
            config.headers.Authorization = "Bearer " + accessToken
        }

        return config
    },
    error => {
        return error
    }
)

axios.interceptors.response.use(
    response => response,
    error => {
        const currentPath = window.location.href.split("#")[1] || "/"

        if (
            error &&
            (error.response?.status === 401 || error.response?.status === undefined) &&
            !["/terms-of-service", "/privacy-policy"].includes(currentPath)
        ) {
            if (isMobile()) {
                const navigate = useNavigate()
                navigate("/mobile-oops")
            }

            msalInstance.loginRedirect(loginRequest)

            return new Promise(() => {})
        }

        return Promise.reject(error)
    }
)

function App() {
    const dispatch = useDispatch()
    const localSettings = useReadLocalStorage("personalSettings")
    const workflowSettings = useReadLocalStorage("workflowSettings")
    const selectedDocs = useReadLocalStorage("selectedDocs")
    const showTokens = useReadLocalStorage("showTokens")
    const [isShowTokens, setShowTokens] = useLocalStorage("showTokens", showTokens || false)

    useHotkeys(
        "ctrl+shift+x",
        () => {
            toast.info(`Show tokens: ${!isShowTokens ? "ON" : "OFF"}`, {
                position: "top-center",
                autoClose: 2000,
            })
            setShowTokens(!isShowTokens)
        },
        [setShowTokens, isShowTokens]
    )
    // TODO: remove local setup after testing
    useEffectOnce(() => {
        // @ts-ignore
        dispatch(setAnswerGenerationPromptTemplate(localSettings?.answerGenerationPromptTemplate || ""))
        // @ts-ignore
        dispatch(
            setCognitiveSearchQueryGenerationPromptTemplate(
                // @ts-ignore
                localSettings?.cognitiveSearchQueryGenerationPromptTemplate || ""
            )
        )
        // @ts-ignore
        dispatch(setExcludeCategory(localSettings?.useExcludeCategory || ""))
        // @ts-ignore
        dispatch(setUseSuggestFollowupQuestions(localSettings?.useSuggestFollowupQuestions || false))
        // @ts-ignore
        dispatch(
            // @ts-ignore
            setPreselectContexFilesMode(localSettings?.preselectContextFilesMode || PreselectContextFilesModes.Disabled)
        )
        // @ts-ignore
        dispatch(setSearchApproach(localSettings?.searchApproach || SearchApproaches.Auto))
        // @ts-ignore
        dispatch(setTemperatureCount(localSettings?.temperatureCount || 0.3))
        // @ts-ignore
        dispatch(setDocsPreselectSearchThreshold(localSettings?.docsPreselectSearchThreshold || 50))
        // @ts-ignore
        dispatch(setDocsSearchThreshold(localSettings?.docsSearchThreshold || 30))
        // @ts-ignore
        dispatch(setModel(localSettings?.selectedModel || ModelVersions.GPT4))
        // @ts-ignore
        dispatch(setAssistantType(localSettings?.assistantType || AssistantTypes.DOC_ASSISTANT))
        dispatch(setSelectedDocuments(selectedDocs || []))
        // @ts-ignore
        dispatch(setShowWelcomeMessage(localSettings?.showWelcomeMessage || false))
        // @ts-ignore
        dispatch(setAnswerStream(localSettings?.answerStream || ""))
        // @ts-ignore
        if (localSettings?.showWelcomeMessage === undefined) {
            dispatch(setShowWelcomeMessage(true))
        }

        // @ts-ignore
        dispatch(setPdfCitationDisplayMode(localSettings?.pdfCitationDisplayMode || DownloadType.Auto))

        dispatch(
            // @ts-ignore
            setCitationsHighlightMode(localSettings?.citationsHighlightMode || CitationsHighlightMode.SIMPLE_PRIORITY)
        )

        // @ts-ignore
        dispatch(setWorkflowJSON(workflowSettings?.workflowJSON || ""))

        const provider =
            ProviderOptions && Array.isArray(ProviderOptions) ? ProviderOptions.find(p => p.key === "azure")?.key : ""
        // @ts-ignore
        dispatch(setModelProvider(localSettings?.modelProvider || provider || ""))
        // @ts-ignore
        dispatch(setImproveSearchQueryMode(localSettings?.improveSearchQueryMode || ImproveSearchQueryModes.Manual))
    })

    return (
        <ThemeProvider theme={theme}>
            <ModalProvider>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <CssBaseline />
                    <AuthenticatedTemplate>
                        <RouterProvider router={router} />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <RouterProvider router={router} />
                    </UnauthenticatedTemplate>
                    <LoginSuccessListener />
                    <ToastContainer
                        position="top-right"
                        style={{
                            top: "60px",
                            zIndex: 10,
                        }}
                        draggable
                    />
                </Worker>
            </ModalProvider>
        </ThemeProvider>
    )
}

export default withAITracking(reactPlugin, withTranslation()(App))
