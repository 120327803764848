import React, { useEffect, useState } from "react"
import {
    Badge,
    Box,
    Button,
    Card,
    Chip,
    Drawer,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Paper,
    Switch,
    Typography,
} from "@mui/material"
import { Clear, Done, Notifications } from "@mui/icons-material"
import { deserializeNotification, SerializedNotification } from "./NotificationSerialization"
import { NotificationCenterItem } from "react-toastify/addons/use-notification-center"
import { format } from "date-fns"
import { useAzureWebPubSubNotifications } from "@/components/NotificationCenter/useAzureWebPubSub"
import { Id } from "react-toastify"
import axios from "axios"
import { useAddUpdateNotification, useNotifications } from "@/components/NotificationCenter/useNotifications"

export interface CustomNotificationCenterItem extends NotificationCenterItem {
    popup?: boolean
    data?: {
        addToNC?: boolean
    }
}

const markNotificationsAsRead = async (notificationIds: Id[]) => {
    try {
        const notificationIdsWithLenMoreThanOne = notificationIds.filter(id => String(id).length > 5)
        const body = { notification_ids: notificationIdsWithLenMoreThanOne }

        await axios.post("/notifications/mark-as-read", body, {
            headers: {
                "Content-Type": "application/json",
            },
        })
    } catch (error) {
        console.error("Error marking notifications as read:", error)
    }
}

function renderNotification(notification: NotificationCenterItem) {
    return (
        <>
            {typeof notification.content === "string" ? (
                <Typography variant="body1">{notification.content}</Typography>
            ) : Array.isArray(notification.content) ? (
                notification.content.length === 1 ? (
                    <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                            __html: notification.content[0].props.dangerouslySetInnerHTML?.__html || "",
                        }}
                    />
                ) : (
                    notification.content.map((item, index) => (
                        <div
                            key={index}
                            dangerouslySetInnerHTML={{
                                __html: item.props.dangerouslySetInnerHTML?.__html || "",
                            }}
                        />
                    ))
                )
            ) : null}
        </>
    )
}

export const NotificationCenter: React.FC = () => {
    const { notificationsData, isLoading } = useNotifications()
    const { notificationsPubSubClient, isNotificationsClientConnected } = useAzureWebPubSubNotifications()
    const [openNotification, setOpenNotification] = useState<boolean>(false)
    const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(true)
    const { add_update_notification, notifications, update } = useAddUpdateNotification()

    useEffect(() => {
        const initialData = notificationsData
            .map(deserializeNotification)
            .filter((notification: NotificationCenterItem | null) => notification !== null)

        initialData.forEach((job: any) => {
            add_update_notification(job)
        })
    }, [notificationsData])

    useEffect(() => {
        if (isNotificationsClientConnected && notificationsPubSubClient) {
            notificationsPubSubClient.on("group-message", e => {
                const notification = deserializeNotification(e.message.data as SerializedNotification)

                if (notification) {
                    add_update_notification(notification)
                }
            })
        }
    }, [isNotificationsClientConnected, notificationsPubSubClient])

    const toggleFilter = () => setShowUnreadOnly(prevState => !prevState)
    const markAsRead_ = async (notificationIds: Id[]) => {
        try {
            await markNotificationsAsRead(notificationIds)
        } catch (error) {
            console.error("Error marking notifications as read:", error)
        }
    }

    const markAsRead = (notification: any) => {
        if (notification) {
            update(notification.id, { ...notification, read: true })
            markAsRead_([notification.id])
        }
    }

    const markAllAsRead = async () => {
        const notificationIds = notifications.map(notification => notification.id)

        try {
            await markNotificationsAsRead(notificationIds)
            for (const notification of notifications) {
                update(notification.id, { ...notification, read: true })
            }
        } catch (error) {
            console.error("Error marking all notifications as read:", error)
        }
    }

    const uniqueNotifications: NotificationCenterItem[] = notifications
        .reduce((acc: any, notification: any) => {
            const existingNotification = acc.find((item: any) => item.id === notification.id)
            if (existingNotification) {
                if (new Date(notification.update_date) > new Date(existingNotification.update_date)) {
                    return acc.map((item: any) => (item.id === notification.id ? notification : item))
                }
                return acc
            }
            return [...acc, notification]
        }, [])
        .filter(
            (notification: CustomNotificationCenterItem) =>
                !!(notification as CustomNotificationCenterItem).data?.addToNC
        )

    return (
        <>
            <IconButton size="large" onClick={() => setOpenNotification(true)} color="inherit">
                <Badge
                    badgeContent={uniqueNotifications.filter(notification => !notification.read).length}
                    color="error">
                    <Notifications />
                </Badge>
            </IconButton>
            <Drawer
                anchor="right"
                open={openNotification}
                onClose={() => setOpenNotification(false)}
                sx={{
                    "& .MuiDrawer-paper": {
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "#F1F1F1",
                    },
                }}>
                <Paper
                    elevation={1}
                    component={"header"}
                    sx={{
                        width: "550px",
                        padding: "13px 20px",
                        paddingLeft: "13px",
                        paddingBottom: "18.5px",
                        zIndex: 1,
                        borderRadius: "0px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                    <Typography
                        variant="h2"
                        component="div"
                        sx={{ marginTop: "10px", paddingLeft: "0px", backgroundColor: "#FBFBFB" }}>
                        Notification center
                    </Typography>
                    <IconButton
                        onClick={() => setOpenNotification(false)}
                        type="button"
                        color="error"
                        sx={{
                            p: "10px",
                            position: "absolute",
                            top: "9px",
                            right: "10px",
                        }}>
                        <Clear />
                    </IconButton>
                </Paper>
                <FormGroup sx={{ padding: "12px" }}>
                    <FormControlLabel
                        control={<Switch color="secondary" onChange={toggleFilter} checked={showUnreadOnly} />}
                        label="Show unread only"
                    />
                </FormGroup>
                <Box
                    sx={{
                        overflowY: "auto",
                        flexGrow: 1,
                        padding: "12px",
                    }}>
                    {uniqueNotifications
                        .filter(notification => !showUnreadOnly || !notification.read)
                        .sort((a, b) => b.createdAt - a.createdAt)
                        .map(notification => {
                            // Convert timestamp to a human-readable format
                            const formattedDate = format(new Date(notification.createdAt), "MM-dd HH:mm:ss")

                            return (
                                <Card
                                    key={notification.id}
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: notification.read ? "#F1F1F1" : "#FFFFFF",
                                        marginBottom: 1,
                                        borderRadius: 0,
                                        "&:hover": { backgroundColor: "#F9F9F9" },
                                        padding: "12px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                    <Box
                                        sx={{
                                            maxHeight: "280px",
                                            maxWidth: "370px",
                                            minWidth: "370px",
                                            overflowY: "auto",
                                            whiteSpace: "pre-line",
                                            alignSelf: "center",
                                        }}>
                                        {renderNotification(notification)}
                                    </Box>
                                    <Box justifyContent="space-evenly" flexDirection="column" display={"flex"}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            sx={{ paddingRight: "8px", paddingLeft: "8px" }}>
                                            {formattedDate}
                                        </Typography>
                                        <Chip
                                            icon={<Done />}
                                            label="Mark as read"
                                            onClick={() => markAsRead(notification as unknown)}
                                            color="success"
                                            size="small"
                                            sx={{
                                                display: notification.read ? "none" : "flex",
                                                marginTop: "10px",
                                            }}
                                        />
                                    </Box>
                                </Card>
                            )
                        })}
                    {(!uniqueNotifications.length || (uniqueNotifications.length === 0 && showUnreadOnly)) && (
                        <Typography sx={{ textAlign: "center", marginTop: "20px" }}>
                            Your queue is empty! You are all set 🎉
                        </Typography>
                    )}
                </Box>

                <Grid container sx={{ padding: "20px" }} justifyContent="center">
                    <Button variant="contained" onClick={markAllAsRead} sx={{ width: "40%" }}>
                        Mark all as read
                    </Button>
                </Grid>
            </Drawer>
        </>
    )
}
