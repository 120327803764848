import axios from "axios"

export const initUser = async (): Promise<void> => {
    try {
        const response = await axios.post(
            `/user/init`,
            {},
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )

        if (response.status !== 200) {
            throw new Error("Could not init user")
        }

        return response.data
    } catch (error) {
        throw new Error("Could not init user")
    }
}
